<div class="search">
  <div class="search__field">
    <ev-input-reactive type="search" [parentForm]="searchForm" [formControlNameInput]="'query'" (keyup.enter)="searchRealEstate(query.value)" (onFocus)="searchRealEstate(query.value)"> </ev-input-reactive>
    <button class="search" (click)="searchRealEstate(query.value)"></button>
  </div>
  <div class="search__results" *ngIf="realEstates" (clickOutside)="realEstates = []">
    <search-estate-results [realEstates]="realEstates" (selectedRealEstate)="setSelectedRealEstate($event)"></search-estate-results>
    <div *ngIf="loading" class="loader">
      <ev-loader [size]="110"></ev-loader>
    </div>
  </div>
</div>

<div class="estate" *ngIf="selectedRealEstate;else noEstate">
  <div class="selected-estate">
    <div class="selected-estate__details">
      <h1>{{ selectedRealEstate.streetName }} {{ selectedRealEstate.streetNumber }}{{ selectedRealEstate.streetLetter }}, {{ selectedRealEstate.postalCode }} {{ selectedRealEstate.postOffice }}</h1>
      <h2>{{ selectedRealEstate.cadastre }}</h2>
      <h2>{{ selectedRealEstate.estateId }}</h2>
    </div>
    <div class="selected-estate__upload">
      <div class="selected-estate__upload--mobile">
        <ev-file-upload [parentForm]="imageForm" [formControlNameInput]="'image'" (fileChange)="onImageChange($event)" acceptType="image/*" label="Ta bilde"></ev-file-upload>
      </div>
      <div class="selected-estate__upload--desktop">
        <ev-file-upload acceptType="image/*" label="Last opp bilde" (fileChange)="onImageChange($event)"></ev-file-upload>
      </div>
    </div>
  </div>

  <div class="estate-images" *ngIf="images && images.length > 0">
    <div class="estate-images__container">
      <h2>I bruk:</h2>
      <div class="estate-image" *ngFor="let image of images;">
        <ng-container *ngIf="!image.archived">
          <div class="estate-image__container">
            <div class="image">
              <img class="image__archived" src="{{image.url}}" alt="Aktivt bilde av eiendom." />
              <div class="image__main" *ngIf="image.main;" >Forside</div>
            </div>
            <div class="image-buttons">
              <button class="image-buttons__button" *ngIf="!image.main;" (click)="setMainImage(image.filename)">Sett forsidebilde</button>
              <button class="image-buttons__button" (click)="setArchivedImage(image.filename)">Ikke bruk</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="estate-images__container">
      <h2>Arkiverte: </h2>
      <div class="estate-image" *ngFor="let image of images;">
        <ng-container *ngIf="image.archived">
          <div class="estate-image__container">
            <div class="image">
              <img class="image__archived" src="{{image.url}}" alt="Aktivt bilde av eiendom." />
              <div class="image__main" *ngIf="image.main;" >Forside</div>
            </div>
            <div class="image-buttons">
              <button class="image-buttons__button" (click)="setActiveImage(image.filename)">Bruk</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #noEstate>
  <h1>Søk og velg en eiendom for å kunne laste opp bilder.</h1>
</ng-template>
