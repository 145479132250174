import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { CommercialSaleService } from "../../services/commercial-sale.service";
import { Agent } from "../../../shared/models/agent.model";
import { ReferenceTypesService } from "../../../shared/services/reference-types.service";
import { catchError, concatMap, finalize, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { ActivatedRoute, Router } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { Sale, SaleWithUnits } from '../../models/sale.model';
import { Unit } from '../../models/unit.model';
import { ToastService } from "../../../shared/services/toast.service";
import { WorkQueueService } from 'src/app/shared/services/workqueue.service';
import { WorkQueueSourceTableId } from 'src/app/shared/models/workqueue-source-table-id.enum';
import { WorkQueueTypeId } from 'src/app/shared/models/workqueue-type-id.enum';
import { WorkQueueItem } from 'src/app/shared/models/workqueue-item.model';
import { ConnectedEstateUnitsService } from '../../../shared/services/connected-estate-units.service';
import { IDropDownItem } from "ev-dropdown-reactive";
import { ITooltip } from "ev-tooltip";
import { MapMarker } from 'src/app/shared/components/static-map/static-map.component';
import { MapMarkerUtil } from 'src/app/shared/components/static-map/static-map-marker.util';
import { DecimalPipe } from "@angular/common";
import { VersionObject } from './components/versions/versions.component';

@Component({
    selector: 'commercial-sale-page',
    templateUrl: './commercial-sale-page.component.html',
    styleUrls: ['./commercial-sale-page.component.scss']
})
export class CommercialSalePageComponent implements OnInit {

    numberPipe: DecimalPipe = new DecimalPipe('nb-NO');

    connectedSaleUnits: Unit[] = [];
    connectedSaleUnitsMapMarkers$: Observable<MapMarker[]>;
    estateSaleParams$: Observable<EstateSaleParams>;
    currentEstate$: Observable<SaleWithUnits | null>

    estateSaleId: number;
    isSaving: boolean = false;
    versions: VersionObject[] = []
    selected: number;
    saleWithUnits: SaleWithUnits;

    emptyBTAandBRA: ValidatorFn = (control: AbstractControl):
        ValidationErrors | null => {
        const totalGrossArea = control.get('totalGrossArea');
        const totalUsableArea = control.get('totalUsableArea');

        const grossAreaHasValue = totalGrossArea.value > 0;
        const usableAreaHasValue = totalUsableArea.value > 0;

        if (grossAreaHasValue || usableAreaHasValue) {
            return null
        } else {
            return { emptyBTAandBRA: true }
        }
    };

    commercialSaleForm = new UntypedFormGroup({
        sourceTypeId: new UntypedFormControl(null, Validators.required),
        sourceInformationIdentification: new UntypedFormControl(null),
        sourceURL: new UntypedFormControl(null),
        informationDate: new UntypedFormControl(null, Validators.required),
        estateSubTypeId: new UntypedFormControl(null, Validators.required),
        // plotArea: new FormControl(null, Validators.pattern("[0-9]*")),
        totalGrossArea: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        totalUsableArea: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        buildYear: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        renovatedYear: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        numberOfFloors: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        parkingTypeId: new UntypedFormControl(null),
        hasElevator: new UntypedFormControl(null),
        energyLabel: new UntypedFormControl(null),
        // breeamCertiticateRatingId: new FormControl(null),
        // technicalStandardTypeId: new FormControl(null),
        askingPrice: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        surveyorAppraisal: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        surveyorAppraisalDate: new UntypedFormControl(null),
        surveyorName: new UntypedFormControl(null),
        propertyValue: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        plotLease: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        plotLeaseContractExpireDate: new UntypedFormControl(null),
        municipalTaxes: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        rentalIncomePerYear: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        estateAgentId: new UntypedFormControl(null),
        estateAgentName: new UntypedFormControl(null),
        rentalStatusId: new UntypedFormControl(null),
        rentalShare: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        adText: new UntypedFormControl(null),
        adTextHtml: new UntypedFormControl(null),
        executionId: new UntypedFormControl(null),
        isApprovedForPublish: new UntypedFormControl(null),
        versionMajorNumber: new UntypedFormControl(null)
    }, { validators: this.emptyBTAandBRA });

    estatesForm = new UntypedFormGroup({
        mapLatitude: new UntypedFormControl(null),
        mapLongitude: new UntypedFormControl(null)
    });

    sourceTypeItems: IDropDownItem[] = [];
    estateSubTypeItems: IDropDownItem[] = [];
    parkingTypeItems: IDropDownItem[] = [];
    hasElevatorOptions = [
        { formControlName: 'hasElevator', value: 'hasElevator', label: '', checked: false }
    ];
    energyLabelItems: IDropDownItem[] = [
        {
            value: 'A',
            key: 'A',
        },
        {
            value: 'B',
            key: 'B',
        },
        {
            value: 'C',
            key: 'C',
        },
        {
            value: 'D',
            key: 'D',
        },
        {
            value: 'E',
            key: 'E',
        },
        {
            value: 'F',
            key: 'F',
        },
        {
            value: 'G',
            key: 'G',
        },
    ];
    breeamCertificateRatingItems: IDropDownItem[] = [];
    technicalStandardTypeItems: IDropDownItem[] = [];
    rentalStatusItems: IDropDownItem[] = [];

    searchSaleTooltip: ITooltip = {
        show: false,
        header: '',
        info: [
            'Her kan du søke frem salg av næringseiendom på en gitt matrikkel/adresse. Dette er et fritekstsøk der du for eksempel kan søke slik:',
            'Adresse "Storgaten 1 Oslo"',
            'Her kan du skrive kommune eller poststed, evt. også postnummer',
            'Matrikkel "301-234/23/0/7"',
            'Knr-Gnr/Bnr/Fnr/Snr. DU trenger ikke ta med Enr og Snr om disse er 0. Du kan også benytte "mellomrom/space" for å skille paramaterene "Knr Gnr Bnr Fnr Snr". Er du usikker på Knr kan kommunenavn benyttes "Oslo 234/23/0/7".',
            'Alternativt kan du søke på diverse ID-er:',
            'FinnID "f211578326"',
            'EstateID "e1547991"',
            'UnitID "u2210553"',
            'NæringssalgID "i5873"',
        ],
        width: 300
    }

    showSearchSaleDialog: boolean = false;
    loadingNextWorkqueueItem: boolean = false;
    currentWorkItem: WorkQueueItem = null;
    remainingWorkqueue: number | string = '  ';

    constructor(
        private estateSale: CommercialSaleService,
        private referenceTypes: ReferenceTypesService,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        private router: Router,
        private workqueueService: WorkQueueService,
        private connectedEstateUnitsService: ConnectedEstateUnitsService) {

    }

    ngOnInit(): void {
        this.estateSaleParams$ = this.activatedRoute.params.pipe(map(p => this.parseParams(p)));
        this.currentEstate$ = this.newEstateFromParams();
        this.currentEstate$.subscribe(estate => this.patchEstateWithUnits(estate));
        this.connectedEstateUnitsService.connectedSaleUnits$.subscribe(units => this.connectedSaleUnits = units);
        this.connectedSaleUnitsMapMarkers$ = this.connectedEstateUnitsService.connectedSaleUnits$
            .pipe(map(units => { return MapMarkerUtil.convertUnitsToMapMarker(units) }), map(units => { return units.filter(u => u.lat && u.lng) }));


        this.connectedEstateUnitsService.setConnectedUnitList([]);

        this.getRemainingWorkQueue();

        this.referenceTypes.getSource().subscribe((source) => {
            this.sourceTypeItems = CommercialSalePageComponent.mapDropdownKeyValues(source);
        });
        this.referenceTypes.getEstateTypeSubTypes(11).subscribe((estateSub) => {
            this.estateSubTypeItems = CommercialSalePageComponent.mapEstateTypeDropdownKeyValues(estateSub);
        });
        this.referenceTypes.getParking().subscribe((parking) => {
            this.parkingTypeItems = CommercialSalePageComponent.mapDropdownKeyValues(parking);
        });
        this.referenceTypes.getBREEAMCertificateRatings().subscribe((breeamCertificateRating) => {
            this.breeamCertificateRatingItems = CommercialSalePageComponent.mapDropdownKeyValues(breeamCertificateRating);
        });
        this.referenceTypes.getTechnicalStandard().subscribe((technicalStandard) => {
            this.technicalStandardTypeItems = CommercialSalePageComponent.mapDropdownKeyValues(technicalStandard);
        });
        this.referenceTypes.getRentalStatus().subscribe((rentalStatus) => {
            this.rentalStatusItems = CommercialSalePageComponent.mapDropdownKeyValues(rentalStatus);
        });
    }

    setEstateAgent($event: Agent) {
        this.commercialSaleForm.get('estateAgentName')?.patchValue($event.name);
        this.commercialSaleForm.get('estateAgentId')?.patchValue($event.id);
    }

    navigateToSale(saleId: number | string, workItem?: WorkQueueItem) {
        this.showSearchSaleDialog = false;

        if (workItem != null) {
            this.router.navigate(['commercialsale/' + saleId, workItem]);
        } else {
            this.router.navigate(['commercialsale', saleId]);
        }
    }

    addConnectedRealEstate(unit: Unit) {
        this.connectedEstateUnitsService.addConnectedUnit(unit);
    }

    deleteConnectedRealEstate(unit: Unit) {
        this.connectedEstateUnitsService.removeConnectedUnit(unit);
    }

    getNextFromWorkQueue() {
        this.loadingNextWorkqueueItem = true;
        this.workqueueService.getCommonWorkQueueNext(WorkQueueSourceTableId.CommercialEstateSale, WorkQueueTypeId.CommercialSale).subscribe(
            wi => {
                this.navigateToSale(wi.sourceTableIdentificationId, wi)
            },
            error => { this.toastService.setMessage({ text: 'Noe feil har skjedd ved å hente workqueue. Prøv igjen', type: 'error' }) },
            () => { this.loadingNextWorkqueueItem = false; });
    }

    putCommonWorkQueueHold() {
        this.workqueueService.putCommonWorkQueueHold(WorkQueueSourceTableId.CommercialEstateSale, this.currentWorkItem.sourceTableIdentificationId).subscribe(() => {
            this.commercialSaleForm.reset();
            this.router.navigate(['commercialsale']);
        });
    }

    saveCurrentSale() {
        this.isSaving = true;
        if (this.estateSaleId) {
            const savedSale = this.commercialSaleForm.value;
            this.estateSale.putSaleWithSaleUnits(
                this.estateSaleId,
                savedSale,
                this.connectedEstateUnitsService.getConnectedUnitsId(),
                this.connectedEstateUnitsService.getRemovedUnitsId()
            ).pipe(
                concatMap(() => this.setWQProcessed()),
                catchError(errorForFirstOrSecondCall => {
                    this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring: ' + errorForFirstOrSecondCall, type: 'error' });
                    // Handle error and return empty data use:
                    return of({});
                    // otherwise:
                    //throw new Error('Error: ' + errorForFirstOrSecondCall.message);
                }),
                finalize(() => {
                    this.isSaving = false;
                })
            ).subscribe(result => {
                // TODO: Give user feedback if save succeeded.
                
                let patchIndex = this.saleWithUnits?.sale.findIndex(v => v.executionId == savedSale.executionId);

                if (patchIndex != null) {
                    this.saleWithUnits.sale[patchIndex] = savedSale;
                    this.saleWithUnits.sale[patchIndex].isApprovedForPublish = true;
                    this.versions[patchIndex].isApprovedForPublish = true;      
                };

                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['commercialsale/' + this.estateSaleId]);
            })

        } else {
            const newSale$ = this.estateSale.addSale(this.commercialSaleForm.value).pipe(
                switchMap((response: any) => {
                    this.estateSaleId = response.data.estateSaleId;
                    if (this.connectedEstateUnitsService.getConnectedUnitsId().length) {
                        return this.estateSale.addSaleUnits(response.data.estateSaleId, this.connectedEstateUnitsService.getConnectedUnitsId());
                    }
                    return of({});
                }),
                catchError(errorForFirstOrSecondCall => {
                    this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring: ' + errorForFirstOrSecondCall, type: 'error' });
                    // Handle error and return empty data use:
                    return of({});
                    // otherwise:
                    //throw new Error('Error: ' + errorForFirstOrSecondCall.message);
                }),
                finalize(() => {
                    this.isSaving = false;
                })
            );

            newSale$.subscribe(() => {
                if (this.estateSaleId != null) {
                    this.router.navigate(['commercialsale/' + this.estateSaleId]);
                }
            }, errorForFirstOrSecondCall => {
                this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring: ' + errorForFirstOrSecondCall, type: 'error' });
            })
        }
    }

    resetSale() {
        this.connectedEstateUnitsService.setConnectedUnitList([]);
        this.commercialSaleForm.reset();
        this.estatesForm.reset();

        if (this.currentWorkItem != null) {
            this.workqueueService.putCommonWorkQueueReset(WorkQueueSourceTableId.CommercialEstateSale, this.currentWorkItem.sourceTableIdentificationId)
                .subscribe(() => {
                    this.router.navigate(['commercialsale']);
                });
        } else {
            this.router.navigate(['commercialsale']);
        }
    }

    changedSelected(changedSelected: number) {
        this.selected = changedSelected;

        let params = {version: changedSelected};
        if (this.currentWorkItem != null) {
            params = {...this.currentWorkItem, ...params}
        }

        this.router.navigate([`commercialsale/${this.estateSaleId}`, params]);
    }

    updateCommercialSaleForm(selected: number) {
        this.selected = selected;
        const versionMajorNumberSelected = this.saleWithUnits.sale.find((s: Sale) => s.versionMajorNumber == selected);
        this.commercialSaleForm.patchValue(versionMajorNumberSelected);
    }

    private getRemainingWorkQueue() {
        this.workqueueService.getCommonWorkQueueRemaining(WorkQueueSourceTableId.CommercialEstateSale).subscribe(q => this.remainingWorkqueue = q);
    }

    private setConnectedSaleUnits(units: Unit[]) {
        this.connectedEstateUnitsService.setConnectedUnitList(units);
    }

    private newEstateFromParams(): Observable<SaleWithUnits> {
        return this.estateSaleParams$.pipe(
            switchMap(params => iif(() => params.estateSaleId != null, this.estateSale.getSaleWithUnits(params.estateSaleId), of(null))),
            tap(e => {
                if(e) {                    
                    this.saleWithUnits = e;
                    this.estateSaleId = this.activatedRoute.snapshot.params['estateId'];
                    this.versions = this.mapVersions(e);

                    let version = this.activatedRoute.snapshot.params['version'];
                    if (version != null) {
                        this.updateCommercialSaleForm(version);
                    } else {
                        this.updateCommercialSaleForm(this.versions[this.versions.length - 1]['version']);
                    }
                } else {
                    this.resetSale();
                }
            }),
            catchError(() => {
                this.toastService.setMessage({ text: 'Noe feil har skjedd ved å hente salg. Prøv igjen', type: 'error' })
                return of(null);
            }));
    }

    private parseParams(params: any): EstateSaleParams {
        const estateSaleId = Number.parseInt(params.estateId);
        const version = Number.parseInt(params.version);

        console.log('params?.id != null', params)
        if (params?.id != null) {
            this.currentWorkItem = params as WorkQueueItem;
        } else {
            this.currentWorkItem = null;
        }

        const esParams: EstateSaleParams = { estateSaleId: estateSaleId ? estateSaleId : null, selectedVersion: version ? version : null };
        return esParams;
    }

    private patchEstateWithUnits(estateSale: SaleWithUnits) {
        if (estateSale != null) {
            this.commercialSaleForm.patchValue(estateSale);
            this.setConnectedSaleUnits(estateSale?.estateSaleUnits);

        } else {
            this.commercialSaleForm.reset();
            this.setConnectedSaleUnits([]);
        }
    }

    private static mapDropdownKeyValues(dropdownItems: { id: any; name: any; }[]) {
        return dropdownItems.map(({ id, name }) => ({ key: id, value: name }));
    }

    private static mapEstateTypeDropdownKeyValues(dropdownItems: { id: any; name: any; subName: any, subId: any }[]) {
        return dropdownItems.map(({ subId, subName }) => ({ key: subId, value: subName }));
    }

    private mapVersions(saleWithUnits: SaleWithUnits): VersionObject[] {
        if(saleWithUnits?.sale != null) {
            const newVersionObjects = saleWithUnits.sale.map((obj: Sale) => {
                return {
                    version: obj.versionMajorNumber,
                    isApprovedForPublish: obj.isApprovedForPublish
                }
            });
    
            return newVersionObjects;
        } else {
            return [{version: 1, isApprovedForPublish: false}]
        }
    }

    private setWQProcessed(): Observable<any> {
        if (this.currentWorkItem != null && this.currentWorkItem.sourceTableIdentificationId != null) {
            return this.workqueueService.putCommonWorkQueueProcessed(WorkQueueSourceTableId.CommercialEstateSale, this.currentWorkItem.sourceTableIdentificationId);
        } else {
            return of(1);
        }
    }
}

export interface EstateSaleParams {
    estateSaleId: number | null;
    selectedVersion: number | null;
}
