<div class="commercial-rent-wrapper">
    <div class="rent-input-wrapper">
        <div class="button-collection">
            <div class="row">
                <div class="left">
                    <button (click)="resetRent()">Ny utleie</button>
                    <button (click)="showSearchRentDialog = true">Søk</button>
                    <button class="workqueue" (click)="getNextFromWorkQueue()" [attr.disabled]="currentWorkItem != null || loadingNextWorkqueueItem ? true : null">
                        {{ "Neste fra Workqueue (" + remainingWorkqueue + ")" }}
                    </button>
                </div>
                <div class="right">
                    <button (click)="putCommonWorkQueueHold()">Sett til ekspert</button>
                </div>
            </div>
            <div class="row">
                <div class="workqueue">
                    <div class="title">{{ currentWorkItem?.info }}</div>
                    <div class="instruction">{{ currentWorkItem?.instruction }}</div>
                </div>
            </div>
        </div>

        <div class="commercial-rent">
            <div class="commercial-rent__row first">
                <div class="form__row">
                    <div class="form__column">
                        <div class="validation entry important mandatory">
                            <div class="underlined">
                                <div class="blue-bar"></div>
                                <ev-key-value-pair [key]="'Kilde'" [borderless]="true">
                                    <ev-dropdown-reactive
                                        *ngIf="sourceTypeItems.length"
                                        [height]="'40px'"
                                        [items]="sourceTypeItems"
                                        [parentForm]="commercialRentForm"
                                        [formControlNameInput]="'sourceTypeId'"
                                    >
                                    </ev-dropdown-reactive>
                                </ev-key-value-pair>
                            </div>
                            <div *ngIf="commercialRentForm.get('sourceTypeId').invalid" class="error icon"></div>
                        </div>
                    </div>
                    <div class="form__column">
                        <div class="entry">
                            <ev-key-value-pair [key]="'KildeID'">
                                <ev-input-reactive [type]="'text'" [hideReset]="true" [formControlNameInput]="'sourceInformationIdentification'" [parentForm]="commercialRentForm"></ev-input-reactive>
                            </ev-key-value-pair>
                        </div>
                    </div>
                    <div class="form__column"></div>
                </div>
                <div class="form__row">
                    <div class="form__column2">
                        <div class="url-link entry">
                            <ev-key-value-pair [key]="'URL'" [width]="'50px'">
                                <ev-input-reactive [type]="'text'" [hideReset]="true" [formControlNameInput]="'sourceURL'" [parentForm]="commercialRentForm"></ev-input-reactive>
                            </ev-key-value-pair>
                            <a [href]="this.commercialRentForm.get('sourceURL').value" target="_punch-rent" data-title="A new page">Åpne</a>
                        </div>
                    </div>
                    <div class="form__column"></div>
                </div>
                <div class="validation entry important mandatory">
                    <div class="underlined">
                        <div class="blue-bar"></div>
                        <ev-key-value-pair [key]="'Dato for annonse'" [borderless]="true">
                            <ev-material-datepicker [formControlNameInput]="'informationDate'" [lineHeight]="'40px'" [parentForm]="commercialRentForm" [isRegular]="true"></ev-material-datepicker>
                        </ev-key-value-pair>
                    </div>
                    <div>
                        <div *ngIf="commercialRentForm.get('informationDate').invalid" class="error icon"></div>
                    </div>
                </div>
            </div>
            <div class="commercial-rent__row">
                <div class="form__row">
                    <div class="form__column">
                        <div class="entry">
                            <ev-key-value-pair [key]="'Utleier'">
                                <ev-input-reactive [hideReset]="true" [formControlNameInput]="'estateAgentName'" [parentForm]="commercialRentForm"></ev-input-reactive>
                                <search-agent (selectedAgentEvent)="setEstateAgent($event)" title="Søk etter utleier"></search-agent>
                            </ev-key-value-pair>
                        </div>
                        <div class="validation entry important mandatory">
                            <div class="underlined">
                                <div class="blue-bar"></div>
                                <ev-key-value-pair [key]="'Eiendomstype'" [borderless]="true">
                                    <ev-dropdown-reactive
                                        *ngIf="estateSubTypeItems.length"
                                        [height]="'40px'"
                                        [items]="estateSubTypeItems"
                                        [parentForm]="commercialRentForm"
                                        [formControlNameInput]="'estateSubTypeId'"
                                    >
                                    </ev-dropdown-reactive>
                                </ev-key-value-pair>
                            </div>
                            <div *ngIf="commercialRentForm.get('estateSubTypeId').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry important mandatory">
                            <div class="underlined">
                                <div class="blue-bar"></div>
                                <ev-key-value-pair [key]="'Ledig areal'" [borderless]="true">
                                    <ev-input-reactive
                                        [type]="'number'"
                                        [hideReset]="true"
                                        [formControlNameInput]="'vacantArea'"
                                        [parentForm]="commercialRentForm"
                                        [pipeTransform]="numberPipe"
                                        [height]="40"
                                    ></ev-input-reactive>
                                </ev-key-value-pair>
                            </div>
                            <div *ngIf="commercialRentForm.get('vacantArea').invalid" class="error icon"></div>
                        </div>
                        <div class="entry">
                            <ev-key-value-pair [key]="'Hvilke etasjer'">
                                <ev-input-reactive [type]="'text'" [hideReset]="true" [formControlNameInput]="'floors'" [parentForm]="commercialRentForm"></ev-input-reactive>
                            </ev-key-value-pair>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Leie hel måned'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formControlNameInput]="'monthlyRent'"
                                    [parentForm]="commercialRentForm"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialRentForm.get('monthlyRent').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Leie kvm per år'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formControlNameInput]="'rentPerSqmPerYear'"
                                    [parentForm]="commercialRentForm"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialRentForm.get('rentPerSqmPerYear').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Felleskost tillegg'">
                                <ev-checkbox-list [options]="hasAdditionalCommonCostOptions" [parentForm]="commercialRentForm"></ev-checkbox-list>
                            </ev-key-value-pair>
                        </div>
                        <div class="entry">
                            <ev-key-value-pair [key]="'Oppvarming'">
                                <ev-checkbox-list [options]="hasIncludedElectricityHeatingOptions" [parentForm]="commercialRentForm"></ev-checkbox-list>
                            </ev-key-value-pair>
                        </div>
                        <div class="entry">
                            <ev-key-value-pair [key]="'Parkering'">
                                <ev-dropdown-reactive *ngIf="parkingTypeItems.length" [items]="parkingTypeItems" [parentForm]="commercialRentForm" [formControlNameInput]="'parkingTypeId'">
                                </ev-dropdown-reactive>
                            </ev-key-value-pair>
                        </div>
                        <div class="entry">
                            <ev-key-value-pair [key]="'Heis'">
                                <ev-checkbox-list [options]="hasElevatorOptions" [parentForm]="commercialRentForm"></ev-checkbox-list>
                            </ev-key-value-pair>
                        </div>
                        <div class="entry">
                            <ev-key-value-pair [key]="'Energimerke'">
                                <ev-dropdown-reactive *ngIf="energyLabelItems.length" [items]="energyLabelItems" [parentForm]="commercialRentForm" [formControlNameInput]="'energyLabel'">
                                </ev-dropdown-reactive>
                            </ev-key-value-pair>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Totalt BTA for bygget'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formControlNameInput]="'totalGrossArea'"
                                    [parentForm]="commercialRentForm"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialRentForm.get('totalGrossArea').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Totalt BRA for bygget'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formControlNameInput]="'totalUsableArea'"
                                    [parentForm]="commercialRentForm"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialRentForm.get('totalUsableArea').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Byggeår'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formControlNameInput]="'buildYear'"
                                    [parentForm]="commercialRentForm"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialRentForm.get('buildYear').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Renovert år'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formControlNameInput]="'renovatedYear'"
                                    [parentForm]="commercialRentForm"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialRentForm.get('renovatedYear').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Antall etasjer i bygget'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formControlNameInput]="'numberOfFloors'"
                                    [parentForm]="commercialRentForm"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialRentForm.get('numberOfFloors').invalid" class="error icon"></div>
                        </div>
                    </div>
                    <div class="connected__units">
                        <div class="lower_button-collection">
                            <search-estate (selectedRealEstateEvent)="addConnectedRealEstate($event)"></search-estate>
                            <div class="row">
                                <button (click)="saveCurrentRent()" [attr.disabled]="isSaving || commercialRentForm.invalid ? true : null">Lagre</button>
                                <button (click)="resetRent()">Avbryt</button>
                            </div>
                        </div>
                        <ul>
                            <li *ngFor="let saleUnit of connectedRentUnits">
                                <div title="{{ 'uid: ' + saleUnit.unitId }}">
                                    <div>{{ saleUnit.streetName }} {{ saleUnit.streetNumber }}{{ saleUnit.streetLetter }}, {{ saleUnit.postalCode }} {{ saleUnit.postOffice }}</div>
                                    <div>{{ saleUnit.cadastre }}</div>
                                </div>
                                <button class="light" (click)="deleteConnectedRealEstate(saleUnit)">Fjern</button>
                            </li>
                        </ul>

                        <div class="map-wrapper">
                            <static-map [mapMarkers]="connectedRentUnitsMapMarkers$ | async" [index]="2"></static-map>
                        </div>
                        <div class="form__column">
                            <div class="ev-textarea">
                                <ev-textarea-reactive
                                    [variant]="'big'"
                                    [title]="''"
                                    [placeholder]="'Lim inn annonsetekst her'"
                                    [formControlNameInput]="'adText'"
                                    [parentForm]="commercialRentForm"
                                ></ev-textarea-reactive>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ev-dialog [title]="'Søk etter Næringsleie'" [theme]="'green'" [visible]="showSearchRentDialog" (close)="showSearchRentDialog = false" [tooltip]="searchSaleTooltip" [hideFooter]="true">
            <search-rent (selectedRent)="navigateToRent($event)"></search-rent>
        </ev-dialog>
    </div>

    <div class="finn-text">
        <finn-text [adtext]="commercialRentForm.get('adTextHtml').value"></finn-text>
    </div>
</div>

<!-- <div class="validation">
          <ev-key-value-pair [key]="'Tomteareal'">
            <ev-input-reactive
              [type]="'number'"
              [hideReset]="true"
              [formControlNameInput]="'plotArea'"
              [parentForm]="commercialRentForm"
            ></ev-input-reactive>
          </ev-key-value-pair>
          <div *ngIf="commercialRentForm.get('plotArea').invalid" class="error icon"></div>
        </div> -->

<!-- <div>
          <ev-key-value-pair [key]="'BREEAM sertifisering'">
            <ev-dropdown-reactive *ngIf="breeamCertificateRatingItems.length"  [items]="breeamCertificateRatingItems" [parentForm]="commercialRentForm" [formControlNameInput]="'breeamCertiticateRatingId'">
            </ev-dropdown-reactive>
          </ev-key-value-pair>
        </div>
        <div>
          <ev-key-value-pair [key]="'TEK Standard'">
            <ev-dropdown-reactive *ngIf="technicalStandardTypeItems.length" [items]="technicalStandardTypeItems" [parentForm]="commercialRentForm" [formControlNameInput]="'technicalStandardTypeId'">
            </ev-dropdown-reactive>
          </ev-key-value-pair>
        </div> -->
