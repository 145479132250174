import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    appInsights: ApplicationInsights;

    constructor(private _router: Router) {
        const angularPlugin = new AngularPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.applicationInsightsKey,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: this._router },
                },
            },
        });

        this.appInsights.loadAppInsights();
    }

    logPageView(name: string, url: string) {
        // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url,
        });
    }
    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
    }
    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}
