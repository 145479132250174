<div class="toolkit">
  <div class="toolkit__header">
    <div class="row controls">
      <div class="column">
        <button [disabled]="loadingNextWorkQueueItem || currentWorkItem != null" (click)="showSearchUnitDialog = true">Søk eiendom</button>
        <button class="workqueue" [disabled]="loadingNextWorkQueueItem || currentWorkItem != null" (click)="getNextFromWorkQueue()">{{'Neste fra Workqueue (' + remainingWorkQueue + ')'}}</button>
      </div>
    </div>
    <div class="row">
      <div class="column expert">
        <div class="instruction-line">
          <div class="instruction" *ngIf="currentWorkItem?.instruction != null">{{ currentWorkItem?.instruction + ': '}} </div>
          <div>{{currentWorkItem?.info}} {{address}}</div>
        </div>
        <button (click)="putCommonWorkQueueHold()">Sett til ekspert</button>
      </div>

    </div>
  </div>
  <div class="toolkit__main">
    <div class="row">
      <div class="column">
        <ev-key-value-pair [key]="'Subtype'">
          <ev-dropdown-reactive  [items]="estateTypeItems" [parentForm]="estateTypeCorrectionForm" [formControlNameInput]="'estateTypeId'"></ev-dropdown-reactive>
        </ev-key-value-pair>
      </div>
        <ev-dropdown-reactive class="sub-type-dropdown" [items]="estateSubTypeItems" [parentForm]="estateTypeCorrectionForm" [formControlNameInput]="'estateSubTypeId'"></ev-dropdown-reactive>
      <div class="column right">
        <button (click)="goToLink()" [disabled]="!estateId">Åpne i Eiendomsverdi</button>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <ev-key-value-pair [key]="'Kommentar'">
          <ev-input-reactive
            [type]="'text'"
            [hideReset]="true"
            [formControlNameInput]="'estateSubTypeComment'"
            [parentForm]="estateTypeCorrectionForm"
          ></ev-input-reactive>
        </ev-key-value-pair>
      </div>
    </div>
  </div>
  <div class="toolkit__footer">
    <div class="message">
      <div *ngIf="saved">Lagret endringer</div>
    </div>
    <div class="controls">
      <button [disabled]="estateTypeCorrectionForm.invalid" (click)="saveEstateTypeCorrection()">Lagre</button>
      <button [disabled]="currentWorkItem == null" (click)="cancel()">Avbryt</button>
    </div>
  </div>
  <div class="toolkit__info">
    <div *ngIf="publicBuildings">
      <simple-table title="Eksisterende bygninger" [headers]="publicBuildingsTableHeader" [rows]="publicBuildings"></simple-table>
    </div>
    <div *ngIf="organizationUnits">
      <simple-table title="Virksomheter registrert på eiendommen" [headers]="organizationUnitTableHeader" [rows]="organizationUnits"></simple-table>
    </div>
    <div *ngIf="publicOwners">
      <simple-table title="Eiere av eiendommen" [headers]="publicOwnersTableHeader" [rows]="publicOwners"></simple-table>
    </div>
  </div>
</div>

<search-modal title="Søk etter Eiendom" (search)="searchEstates($event)" (close)="showSearchUnitDialog = false" [showModal]="showSearchUnitDialog" [loading]="loadingEstatesSearch">
  <real-estate-result [loading]="loadingEstatesSearch" [realEstates]="estateResult" [dialog]="true" (selectedRealEstate)="navigateToUnit($event)"></real-estate-result>
</search-modal>
