import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Unit } from "../models/unit.model";
import { PublicOwner } from "../../estate-type-correction/models/publicOwner.model";
import { PublicBuilding } from "../../estate-type-correction/models/publicBuilding.model";
import { OrganizationUnit } from "../../estate-type-correction/models/organizationUnit.model";

@Injectable({
  providedIn: 'root'
})
export class EstatesService {

  constructor(private http: HttpClient) { }

  searchEstates(searchQuery: string): Observable<Unit[]> {
    return this.http.get<{data: Unit[]}>(`${environment.apiUrl}/common/estates/search?limit=201&offset=1&query=${searchQuery}`).pipe(
      map(result => result.data)
    );
  }

  getEstatePublicOwners(unitId: string): Observable<PublicOwner[]> {
    return this.http.get<{data: PublicOwner[]}>(`${environment.apiUrl}/common/estates/${unitId}/publicowners`).pipe(map(result => result.data));
  }

  getEstatePublicBuildings(unitId: string): Observable<PublicBuilding[]> {
    return this.http.get<{data: PublicBuilding[]}>(`${environment.apiUrl}/common/estates/${unitId}/publicbuildings`).pipe(map(result => result.data));
  }

  getEstateOrganizationUnits(unitId: string): Observable<OrganizationUnit[]> {
    return this.http.get<{data: OrganizationUnit[]}>(`${environment.apiUrl}/common/estates/${unitId}/organizationunits`).pipe(map(result => result.data));
  }

}
